const REPOSITION_ARROWS = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5 12.5V18.3333" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M10.5 1.66699V7.50033" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M10.5 1.66699L13 4.16699" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M8 4.16699L10.5 1.66699" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M10.5 18.334L8 15.834" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M13 15.834L10.5 18.334" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M8.00033 10H2.16699" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M18.8333 10H13" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M18.834 10L16.334 12.5" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M16.334 7.5L18.834 10" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M2.16699 10L4.66699 7.5" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
<path d="M4.66699 12.5L2.16699 10" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
</svg>

`;

export default REPOSITION_ARROWS;
