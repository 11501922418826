const CLOCK = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="white"/>
<g clip-path="url(#clip0_16292_21604)">
<path d="M12 7.5V12L15 13.5M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12Z" stroke="#79798A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_16292_21604">
<rect width="18" height="18" fill="white" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>
`;

export default CLOCK;
