import { PortalRole } from '../../shared/constants/portal-role';
import { ScoreType } from '@const/score-types';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  hasPassword: boolean;
  agreedWithLatestTerms: boolean;
  agreedWithLatestPrivacy: boolean;
  permissions: string[];
  sex: string;
  birthDate: string;
  healthInfo: HealthInfo;
  hasPendingDataRequest: boolean;
  createdAt: string;
}

export interface Company {
  id: number;
  employeeId: number;
  name: string;
  logo: string;
  role: PortalRole;
  licencePermissions: string[];
  portalPermissions: string[];
  objectPermissions?: {
    canCreateEvents?: boolean;
    canCreateChallenges?: boolean;
  };
  lastSelectedAt: string;
  createdAt: string;
  user: {
    firstName: string;
    lastName: string;
    id: number;
    picture: string;
  };
}

export enum StartScreens {
  COMMUNITY_MEMBERS = 'COMMUNITY_MEMBERS',
  TEAMS = 'TEAMS',
  ATTRIBUTES = 'ATTRIBUTES',
  BLOGS = 'BLOGS',
  EVENTS = 'EVENTS',
  CHALLENGES = 'CHALLENGES',
  HEALTH_INSIGHTS = 'HEALTH_INSIGHTS',
  INDIVIDUAL_HEALTH_INSIGHTS = 'INDIVIDUAL_HEALTH_INSIGHTS',
  ROLES = 'ROLES',
}

export interface Preferences {
  shownStartScreens: StartScreens[];
  language: string;
}

export interface CompanyPreferences {
  filters?: {
    screen?: string;
    status?: string;
    query?: string;
    additionalColumns?: {
      [key: string]: boolean | string[];
    };
    teamIds?: number[];
  }[];
  healthInsightsSwitcher?: ScoreType;
}

export interface HealthInfo {
  exerciseDuration: string;
  exerciseEffort: string;
  exerciseFrequency: string;
  height: string;
  heightUnit: string;
  mhr: number;
  rhr: number;
  weight: string;
  weightUnit: string;
}
