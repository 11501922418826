import HOME_HEARTED_ICON from './icons/home-hearted';
import ADMIN_ICON from './icons/admin';
import INTERSECT_COMMUNITY_ICON from './icons/intersect-community';
import SUPPORT_ICON from './icons/support';
import LOGOUT_ICON from './icons/logout';
import FACE_PROFILE_ICON from './icons/face-profile';
import PEN_EDIT_ICON from './icons/pen-edit';
import MINUS_CIRCLE_ICON from './icons/minus-circle';
import NOTIFICATION from './icons/notification';
import NOTIFICATION_ACTIVE from './icons/notification-active';
import PRIVACY_POLICY_ICON from './icons/privacy-policy';
import SERVICE_TERMS_ICON from './icons/service-terms';
import X_CIRCLED_ICON from './icons/x-circled';
import DELETE from './icons/delete';
import SEND_INVITE from './icons/send-invite';
import ADD_PLUS from './icons/add-plus';
import SEARCH_ICON from './icons/search';
import REMOVE_ICON from './icons/remove';
import COLUMN_ICON from './icons/column';
import FILE_ICON from './icons/file';
import FILE_GRAPH from './icons/file-graph';
import CALENDAR_ICON from './icons/calendar';
import PLACE_PIN_ICON from './icons/place-pin';
import AWARD_BADGE_ICON from './icons/award-badge';
import WRITE_MESSAGE_ICON from './icons/write-message';
import GROUP_SYMBOL from './icons/group-symbol';
import PERSON from './icons/person';
import INFO_CIRCLE from './icons/info-circle';
import SEND_ICON from './icons/send';
import PHOTO_ICON from './icons/photo';
import MESSAGE_FILE from './icons/message-file';
import ARROW_UP from './icons/arrow-up';
import EYE_ICON from './icons/eye';
import PDF_ICON from './icons/pdf';
import MESSAGE_CHAT from './icons/message-chat';
import ZOOM_IN_ICON from './icons/zoom-in';
import ARROW_RIGHT_ICON from './icons/arrow-right';
import ATTACH_PHOTO from './icons/attach-photo';
import ATTACH_FILE from './icons/attach-file';
import X_CIRCLE_CLOSE from './icons/x-circle-close';
import ARROW_LEFT from './icons/arrow-left';
import CLOSE_ICON from './icons/close';
import SORT_ASC from './icons/sort-asc';
import FILTER_ICON from './icons/filter';
import RESET_ICON from './icons/reset';
import SUMMARY_DOC from './icons/summary-doc';
import MAGIC_WAND from './icons/magic-wand';
import BOLD_ICON from './icons/bold';
import ITALIC_ICON from './icons/italic';
import BLOCKQUOTES_ICON from './icons/blockquotes';
import LINK_ICON from './icons/link';
import LIST_ICON from './icons/list';
import SHARE_ICON from './icons/share';
import ORDERED_LIST_ICON from './icons/ordered-list';
import TEAMS from './icons/teams';
import ATTRIBUTE from './icons/attribute';
import MY_TEAM_ICON from './icons/my-team';
import LANGUAGE_GLOB_ICON from './icons/language-glob';
import CHEVRON_RIGHT from './icons/chevron-right-double';
import CHEVRON_LEFT from './icons/chevron-left-double';

export const CUSTOM_ICONS = [
  {
    name: 'home-hearted',
    svg: HOME_HEARTED_ICON,
  },
  {
    name: 'admin',
    svg: ADMIN_ICON,
  },
  {
    name: 'intersect-community',
    svg: INTERSECT_COMMUNITY_ICON,
  },
  {
    name: 'support',
    svg: SUPPORT_ICON,
  },
  {
    name: 'logout',
    svg: LOGOUT_ICON,
  },
  {
    name: 'face-profile',
    svg: FACE_PROFILE_ICON,
  },
  {
    name: 'pen-edit',
    svg: PEN_EDIT_ICON,
  },
  {
    name: 'minus-circle',
    svg: MINUS_CIRCLE_ICON,
  },
  {
    name: 'notification',
    svg: NOTIFICATION,
  },
  {
    name: 'notification-active',
    svg: NOTIFICATION_ACTIVE,
  },
  {
    name: 'privacy-policy',
    svg: PRIVACY_POLICY_ICON,
  },
  {
    name: 'service-terms',
    svg: SERVICE_TERMS_ICON,
  },
  {
    name: 'send-invite',
    svg: SEND_INVITE,
  },
  {
    name: 'delete',
    svg: DELETE,
  },
  {
    name: 'add-plus',
    svg: ADD_PLUS,
  },
  {
    name: 'search',
    svg: SEARCH_ICON,
  },
  {
    name: 'remove',
    svg: REMOVE_ICON,
  },
  {
    name: 'x-circled',
    svg: X_CIRCLED_ICON,
  },
  {
    name: 'column',
    svg: COLUMN_ICON,
  },
  {
    name: 'file',
    svg: FILE_ICON,
  },
  {
    name: 'file-graph',
    svg: FILE_GRAPH,
  },
  {
    name: 'calendar',
    svg: CALENDAR_ICON,
  },
  {
    name: 'place-pin',
    svg: PLACE_PIN_ICON,
  },
  {
    name: 'award-badge',
    svg: AWARD_BADGE_ICON,
  },
  {
    name: 'write-message',
    svg: WRITE_MESSAGE_ICON,
  },
  {
    name: 'group-symbol',
    svg: GROUP_SYMBOL,
  },
  {
    name: 'person',
    svg: PERSON,
  },
  {
    name: 'info-circle',
    svg: INFO_CIRCLE,
  },
  {
    name: 'send',
    svg: SEND_ICON,
  },
  {
    name: 'photo',
    svg: PHOTO_ICON,
  },
  {
    name: 'message-file',
    svg: MESSAGE_FILE,
  },
  {
    name: 'arrow-up',
    svg: ARROW_UP,
  },
  {
    name: 'eye',
    svg: EYE_ICON,
  },
  {
    name: 'pdf',
    svg: PDF_ICON,
  },
  {
    name: 'message-chat',
    svg: MESSAGE_CHAT,
  },
  {
    name: 'zoom-in',
    svg: ZOOM_IN_ICON,
  },
  {
    name: 'arrow-right',
    svg: ARROW_RIGHT_ICON,
  },
  {
    name: 'attach-photo',
    svg: ATTACH_PHOTO,
  },
  {
    name: 'attach-file',
    svg: ATTACH_FILE,
  },
  {
    name: 'x-circle-close',
    svg: X_CIRCLE_CLOSE,
  },
  {
    name: 'arrow-left',
    svg: ARROW_LEFT,
  },
  {
    name: 'close',
    svg: CLOSE_ICON,
  },
  {
    name: 'sort-asc',
    svg: SORT_ASC,
  },
  {
    name: 'filter',
    svg: FILTER_ICON,
  },
  {
    name: 'reset',
    svg: RESET_ICON,
  },
  {
    name: 'summary-doc',
    svg: SUMMARY_DOC,
  },
  {
    name: 'magic-wand',
    svg: MAGIC_WAND,
  },
  {
    name: 'bold-format',
    svg: BOLD_ICON,
  },
  {
    name: 'italic-format',
    svg: ITALIC_ICON,
  },
  {
    name: 'blockquotes-format',
    svg: BLOCKQUOTES_ICON,
  },
  {
    name: 'link-format',
    svg: LINK_ICON,
  },
  {
    name: 'list-format',
    svg: LIST_ICON,
  },
  {
    name: 'share',
    svg: SHARE_ICON,
  },
  {
    name: 'ordered-list',
    svg: ORDERED_LIST_ICON,
  },
  {
    name: 'teams',
    svg: TEAMS,
  },
  {
    name: 'attribute',
    svg: ATTRIBUTE,
  },
  {
    name: 'my-team',
    svg: MY_TEAM_ICON,
  },
  {
    name: 'language-glob',
    svg: LANGUAGE_GLOB_ICON,
  },
  {
    name: 'chevron-left',
    svg: CHEVRON_LEFT,
  },
  {
    name: 'chevron-right',
    svg: CHEVRON_RIGHT,
  }
];
